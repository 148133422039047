import React from "react";

import Education from "../components/Education";

export default function EducationPage() {
  return (
    <>
      <Education />
    </>
  );
}
