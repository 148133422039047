import Article from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

import Hero from "../components/Hero";

export default function LandingPage() {
  let {
    contentNode: { featuredImage, corner },
    // isPreview,
  } = usePageContext();

  return (
    <>
      <Hero
        featuredImage={featuredImage?.node && featuredImage.node}
        corner={corner}
      />
      <Article />
    </>
  );
}
