// extracted by mini-css-extract-plugin
export var body = "CornerCard-module--body--wtO9S";
export var component = "CornerCard-module--component--gks+n";
export var cta = "CornerCard-module--cta--0YRV5";
export var isCompact = "CornerCard-module--isCompact--yO9T4";
export var map = "CornerCard-module--map--iXQTG";
export var municipalities = "CornerCard-module--municipalities--o1jRs";
export var municipalitiesItem = "CornerCard-module--municipalitiesItem--e2zjr";
export var themeNo = "CornerCard-module--themeNo--3QvCF";
export var themeNv = "CornerCard-module--themeNv--EeaqD";
export var themeSo = "CornerCard-module--themeSo--1+8iS";
export var themeSv = "CornerCard-module--themeSv--jAZk4";
export var title = "CornerCard-module--title--v65D5";