import {
  URLSearchParamsProvider,
  LazyMinisearchSearchBackendProvider,
  SearchContextDebug,
  SearchPagination,
  SearchResults,
} from "@whitespace/gatsby-plugin-search";
import clsx from "clsx";
import { mapValues } from "lodash-es";
import * as React from "react";
import * as yup from "yup";

import * as layout from "../@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import useCornersInfo from "../hooks/useCornersInfo";
import { useUserContext } from "../hooks/useUserContext";

import * as searchStyles from "./Search.module.css";
import SearchHeader from "./SearchHeader";

export default function Search({ ...restProps }) {
  const [{ theme }] = useUserContext();
  const corners = useCornersInfo();
  const corner = corners[theme];

  if (!corner) return null;

  const { id: cornerID } = corner;

  return (
    <div
      className={clsx(
        searchStyles.component,
        layout.component,
        layout.componentWidthFull,
      )}
      {...restProps}
    >
      <URLSearchParamsProvider
        schema={yup.object({
          corners: yup.string().oneOf(["all", ""]).ensure(),
          municipalities: yup.array().of(yup.string()).ensure(),
          orientations: yup.array().of(yup.string()).ensure(),
          duration: yup.array().of(yup.string()).ensure(),
          studyPace: yup.array().of(yup.string().trim()).ensure(),
          flags: yup.array().of(yup.string()).ensure(),
          openForRegistration: yup.boolean().default(true),
          remoteLearning: yup.boolean().default(false),
          vocationalAdults: yup.boolean().default(false),
          apprenticeAdults: yup.boolean().default(false),
          combinedLearning: yup.boolean().default(false),
          courseStartSemesters: yup.array().of(yup.string()).ensure(),
        })}
        forcedParams={{
          contentType: [
            "educationnv",
            "educationno",
            "educationsv",
            "educationso",
          ],
        }}
        encodeParam={(value, param) => {
          if (param === "openForRegistration") {
            return String(value);
          }
          return value;
        }}
        decodeParam={(value, param) => {
          if (param === "openForRegistration") {
            return value !== "false" && value !== false;
          }
          return value;
        }}
        isEmptyParamValue={(value, key, isEmptyValue) =>
          key === "openForRegistration" ? false : isEmptyValue(value, key)
        }
      >
        <LazyMinisearchSearchBackendProvider
          preload={true}
          transformParams={(params) => ({
            ...mapValues(params, (value) => (value === false ? null : value)),
            corners: null,
            corner: params.corners === "all" ? Object.keys(corners) : cornerID,
            hitsPerPage: 40,
          })}
        >
          <SearchHeader corner={corner} />
          <div className={clsx(layout.component, searchStyles.content)}>
            {process.env.NODE_ENV !== "production" && (
              <details>
                <summary>Debug</summary>
                <SearchContextDebug />
              </details>
            )}
            <SearchResults />
            <SearchPagination className={searchStyles.pagination} />
          </div>
        </LazyMinisearchSearchBackendProvider>
      </URLSearchParamsProvider>
    </div>
  );
}
