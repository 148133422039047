import { Button } from "@whitespace/components/dist/components";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import useCornersInfo from "../hooks/useCornersInfo";

import * as defaultStyles from "./Hero.module.css";

Hero.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  corner: PropTypes.string,
  featuredImage: PropTypes.objectOf(PropTypes.any),
};

export default function Hero({
  styles = defaultStyles,
  className,
  featuredImage,
  corner,
  ...restProps
}) {
  const { t } = useTranslation();

  const corners = useCornersInfo();

  if (!featuredImage) {
    return null;
  }
  return (
    <div className={clsx(styles.component, className)}>
      <Image className={clsx(styles.image)} {...featuredImage} {...restProps} />
      <div className={clsx(styles.content)}>
        <p className={clsx(styles.title)}>{t("findEducationTitle")}</p>
        <Button url={corners[corner]?.searchUri}>
          {t("findEducationButtonLabel")}
        </Button>
      </div>
    </div>
  );
}
