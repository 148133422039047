import { useLocation } from "@gatsbyjs/reach-router";
import {
  Button,
  Link,
  Icon,
  TreeMenu,
  withComponentDefaults,
} from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getTreeStructure } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./HeaderFlyOutMenu.module.css";

export default withComponentDefaults(HeaderFlyOutMenu, "headerFlyOutMenu");

HeaderFlyOutMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

function HeaderFlyOutMenu({ styles = defaultStyles, className, ...restProps }) {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const allPages = usePages();
  const items = getTreeStructure(allPages);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const { items: shortcuts = [] } = useMenu("HELP_MENU");

  // Filter out items that are already inside the shortcut menu
  const filteredItems = items.filter((item) => {
    return !shortcuts.some((filteredItem) => filteredItem.uri === item.uri);
  });

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Button onClick={() => setExpanded(!expanded)} className={styles.toggle}>
        <span className={clsx(styles.label)}>{t("menu")}</span>
        <Icon name="icon-menu" />
      </Button>
      {expanded && (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              setExpanded(false);
            },
          }}
        >
          <div>
            <Button
              className={clsx(styles.closeBtn)}
              aria-label="Close menu"
              onClick={() => {
                setExpanded(false);
              }}
            >
              <Icon name="icon-close" aria-label="Stäng" />
            </Button>
            <div
              className={styles.backdrop}
              onClick={() => setExpanded(false)}
            />
            <div className={styles.content}>
              <TreeMenu
                items={filteredItems}
                location={location}
                className={clsx(styles.menu)}
              />
              {shortcuts.length && (
                <nav className={clsx(styles.helpMenuNav)}>
                  {
                    <ul>
                      {shortcuts.map((item, index) => {
                        return (
                          <li key={index} className={styles.item}>
                            <Link className={styles.link} to={item.url}>
                              {item.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  }
                </nav>
              )}
            </div>
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
