import { camelCase } from "camel-case";
import { useStaticQuery, graphql } from "gatsby";

const educationSettings = () => {
  return useStaticQuery(graphql`
    query EducationSettings {
      wp {
        ...WP_EducationSettings
      }
    }
  `).wp;
};

export default function useEducationSearchSettings(corner) {
  const settings = educationSettings();

  return corner
    ? settings[camelCase(`educationSettings-${corner}`)]
    : settings["educationSettings"];
}
