import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { camelCase } from "camel-case";
import React from "react";
import { useTranslation } from "react-i18next";

import useEducationSearchSettings from "./useEducationSettings";

export default function useRequirements(requirements) {
  const { t } = useTranslation();
  const { processContent } = useHTMLProcessor();
  const { courses, sfi, other } = requirements;
  const { requirementTexts } = useEducationSearchSettings();

  const getText = (requirementText, requirements) => {
    let text = "";

    requirementText.split("{{ options }}").map((item, index) => {
      text += item;
      if (index == 0) {
        text += "<ul>";
        requirements?.map((requirement, index) => {
          const label = t(camelCase(`${requirement}Label`));
          text += `<li key=${index}>${label}</li>`;
        });
        text += "</ul>";
      }
    });

    return processContent(text);
  };

  return (
    (!!courses || !!sfi || !!other) && (
      <div>
        {courses && getText(requirementTexts.courses, courses)}
        {sfi && getText(requirementTexts.sfi, sfi)}
        {other && getText(requirementTexts.other, other)}
      </div>
    )
  );
}
