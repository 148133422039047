import { withComponentDefaults } from "@whitespace/components";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Occasions.module.css";

export default withComponentDefaults(Occasions, "Occasions");

Occasions.propTypes = {
  cancelled: PropTypes.bool,
  className: PropTypes.string,
  dates: PropTypes.array,
  openForRegistration: PropTypes.bool,
  registrationPending: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.string),
};

function Occasions({
  dates,
  cancelled,
  openForRegistration,
  registrationPending,
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        styles.component,
        dates?.length > 1 && styles.componentMulti,
        className,
      )}
      {...restProps}
    >
      {cancelled ? (
        <div className={styles.occasions}>
          <div className={clsx(styles.occasion, styles.occasionCancelled)}>
            <p>{t("cancelledLabel")}</p>
          </div>
        </div>
      ) : registrationPending ? (
        <div className={styles.occasions}>
          <div className={clsx(styles.occasion, styles.occasionPending)}>
            <p>{t("registrationPendingLabel")}</p>
          </div>
        </div>
      ) : openForRegistration ? (
        dates &&
        dates.map(
          ({ applicationDeadline, startDate, admissionDate }, index) => {
            return (
              <div key={index}>
                <p className={styles.occasionTitle}>
                  {dates.length > 1
                    ? t("occasionLabel") + " " + (index + 1)
                    : t("nextOccasionLabel")}
                </p>
                <div className={styles.occasion}>
                  {applicationDeadline && (
                    <div className={styles.occasionDate}>
                      <span className={styles.occasionLabel}>
                        {t("deadlineLabel")}
                      </span>
                      <Time
                        date={applicationDeadline}
                        format={{
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }}
                      />
                    </div>
                  )}
                  {startDate && (
                    <div className={styles.occasionDate}>
                      <span className={styles.occasionLabel}>
                        {t("startDateLabel")}
                      </span>
                      <Time
                        date={startDate}
                        format={{
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }}
                      />
                    </div>
                  )}
                  {admissionDate && (
                    <div className={styles.occasionDate}>
                      <span className={styles.occasionLabel}>
                        {t("admissionDateLabel")}
                      </span>
                      <Time
                        date={admissionDate}
                        format={{
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          },
        )
      ) : (
        <div>
          <div className={clsx(styles.occasion, styles.occasionClosed)}>
            <p>{t("closedForRegistrationLabel")}</p>
          </div>
        </div>
      )}
    </div>
  );
}
