export default {
  color: {
    Default: "#323232",
    info: "#fd4141",
    light: "#fff",
  },
  focusBoxShadow: "0 0 0 3px #000",
  pageTheme: {
    default: {
      default: "rgba(0,0,0,.8)",
      cornerSelectorBackground: "#f2f5ed",
      transparent: "rgba(242, 245, 237, 0.8)",
      cardBackground: "#F2F5ED",
      coverLinkColor: "var(--color-default)",
      listRuleColor: "rgba(242, 245, 237, 0.8)",
      focusBoxShadow: "0 0 0 3px #F2F5ED",
      color: "#1D1C1D",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#000",
      inverse: "#fff",
    },
    Default: {
      default: "#F2F5ED",
      cornerSelectorBackground: "#f2f5ed",
      transparent: "rgba(242, 245, 237, 0.8)",
      cardBackground: "#F2F5ED",
      coverLinkColor: "var(--color-default)",
      listRuleColor: "rgba(242, 245, 237, 0.8)",
      focusBoxShadow: "0 0 0 3px #F2F5ED",
      color: "#1D1C1D",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#000",
      inverse: "#fff",
    },
    Nv: {
      default: "#522583",
      cornerSelectorBackground: "rgba(82, 37, 131, 0.1)",
      transparent: "rgba(82, 37, 131, 0.8)",
      cardBackground: "rgba(82, 37, 131, 0.05)",
      coverLinkColor: "#522583",
      listRuleColor: "#DDDBE0",
      focusBoxShadow: "0 0 0 3px #522583",
      color: "#522583",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#3C1468",
      inverse: "#fff",
    },
    No: {
      default: "#c00d0d",
      cornerSelectorBackground: "rgba(192, 13, 13, 0.1)",
      transparent: "rgba(192, 13, 13, 0.8)",
      cardBackground: "rgba(192, 13, 13, 0.05)",
      coverLinkColor: "#c00d0d",
      listRuleColor: "rgba(192, 13, 13, 0.05)",
      focusBoxShadow: "0 0 0 3px #c00d0d",
      color: "#c00d0d",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#8A1212",
      inverse: "#fff",
    },
    Sv: {
      default: "#294a9f",
      cornerSelectorBackground: "rgba(37, 63, 131, 0.1)",
      transparent: "rgba(41, 74, 159, 0.8)",
      cardBackground: "rgba(82, 37, 131, 0.05)",
      coverLinkColor: "#294a9f",
      listRuleColor: "rgba(82, 37, 131, 0.05)",
      focusBoxShadow: "0 0 0 3px #294a9f2",
      color: "#294a9f",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#193680",
      inverse: "#fff",
    },
    So: {
      default: "#0F700D",
      cornerSelectorBackground: "rgba(37, 134, 35, 0.1)",
      transparent: "rgba(37, 134, 35, 0.8)",
      cardBackground: "rgba(15, 112, 13, 0.05)",
      coverLinkColor: "#0F700D",
      listRuleColor: "rgba(15, 112, 13, 0.05)",
      focusBoxShadow: "0 0 0 3px #0F700D",
      color: "#0F700D",
      colorLight: "rgba(255, 255, 255, 0.1)",
      colorDark: "#136511",
      inverse: "#fff",
    },
  },
  textColor: "rgba(0,0,0,.8)",
  baseFontFamily: "Open Sans",
  baseFontSize: "var(--step-0)",
  headingFontFamily: "Domine",
  headingFontWeight: "700",
  educationOrientationFontWeight: "600",
  linkCurrentTextDecoration: "none",
  linkCurrentFontWeight: "400",
  linkFocusBoxShadow: "0 0 0 4px rgba(25, 154, 255, 0.8)",
  buttonFocusBoxShadow: "0 0 0 4px rgba(25, 154, 255, 0.8)",
  treeMenuRowFocusBoxShadow: "0 0 0 4px rgba(25, 154, 255, 0.8)",
  flow: "1.5rem",
  stepM2: "0.75rem",
  stepM1: "0.875rem",
  step0: "clamp(1.00rem, 0.96rem + 0.18vw, 1.13rem)",
  step1: "clamp(1.20rem, 1.14rem + 0.29vw, 1.41rem)",
  step2: "clamp(1.44rem, 1.35rem + 0.45vw, 1.76rem)",
  step3: "clamp(1.73rem, 1.59rem + 0.67vw, 2.20rem)",
  step4: "clamp(2.07rem, 1.88rem + 0.96vw, 2.75rem)",
  step5: "clamp(2.49rem, 2.22rem + 1.35vw, 3.43rem)",
  stepHero: "clamp(1.5rem, 1.25rem + 1vw, 2.5rem)",
  space3xs: "clamp(0.25rem, 0.20rem + 0.24vw, 0.38rem)",
  space2xs: "clamp(0.50rem, 0.40rem + 0.49vw, 0.75rem)",
  spaceXs: "clamp(0.75rem, 0.60rem + 0.73vw, 1.13rem)",
  spaceS: "clamp(1.00rem, 0.80rem + 0.98vw, 1.50rem)",
  spaceM: "clamp(1.50rem, 1.21rem + 1.46vw, 2.25rem)",
  spaceL: "clamp(2.00rem, 1.61rem + 1.95vw, 3.00rem)",
  spaceXl: "clamp(3.00rem, 2.41rem + 2.93vw, 4.50rem)",
  space2xl: "clamp(4.00rem, 3.22rem + 3.90vw, 6.00rem)",
  space3xl: "clamp(6.00rem, 4.83rem + 5.85vw, 9.00rem)",
  spaceLayoutPadding: "clamp(1.00rem, 1rem + 2vw, 3.50rem)",
  headingLineHeight: "1.2",
  article: {
    preambleFontSize: "var(--step-2)",
    preambleLineHeight: "1.5",
    preambleFontWeight: "400",
    blockQuoteLineHeight: "1.25",
  },
  card: {
    color: "var(--page-theme-color)",
    content: {
      padding: "1.5rem",
      gap: ".25rem",
    },
    title: {
      lineHeight: "1.4",
      fontSize: "var(--step-2)",
    },
    hover: {
      background: "var(--page-theme-card-background)",
    },
  },
  headerMainMenuBackground: "var(--page-theme)",
  headerMainMenuColor: "var(--page-theme-inverse)",
  headerMainMenuSpace: "1.5rem",
  headerMainMenuGap: "2.5rem",
  gap: "0rem",
  gridColumnMinWidth: "21rem",
  gridGap: "2rem",
  treeMenuListGap: "0",
  treeMenuRowMinHeight: "auto",
  treeMenuLinkActiveBackground: "black",
  treeMenuItemBorderRadius: "0.25rem",
  treeMenuRowPadding: "0.75rem",
  treeMenuLinkHoverColor: "black",
  treeMenuLinkHoverBackground: "#efefef",
  treeMenuRowMargin: "0",
  button: {
    borderRadius: "2.25rem",
    currentBackground: "#efefef",
    currentColor: "gray",
  },
  moduleContactBackground: "#f4f4f4",
  moduleContactSurface: "#4d4d4d",
  moduleContactFontSize: "1rem",
  moduleContactActionDecoration: "underline",
  moduleContactFontWeight: "400",
  moduleContactFontStyle: "normal",
  moduleContactBorderWidth: "0",
  moduleContactBorderStyle: "inherit",
  moduleContactBorderColor: "transparent",
  moduleContactBorderRadius: "0",
  moduleContactSpacing: "2rem",
  moduleContactIconColor: "var(--page-theme-color)",
  moduleContactDivider: "0",
  moduleContactDividerSpacing: "0",
  moduleContactGridDefault: "15rem",
  moduleContactGridNarrow: "15rem",
  moduleContactLabelWeight: "700",
  moduleContactLabelSpacing: "0.25rem",
  moduleContactLabelSize: "var(--base-font-size)",
  boxBackground: "#f4f4f4",

  // Cookie Consent
  ccBackground: "#cfd5c6",
  ccForeground: "var(--color-default)",
  ccDeclineBackground: "#90938b",
  ccDeclineForeground: "#fff",
  ccApproveBackground: "green",
  ccApproveForegound: "#fff",
  ccFocus: "red",
  ccTitleSize: "var(--step-1)",
  ccBodySize: "16px",
  ccButtonBorderRadius: "0.25rem",
  ccButtonPadding: "0.5rem 1rem",
  ccButtonGroupMargin: "1rem 0 0 0",

  skipTo: {
    background: "#222",
    border: "1px solid #111",
    color: "#fff",
    margin: "1rem",
    fontFamily: "inherit",
    fontSize: "100%",
    fontWeight: "400",
    lineHeight: "1",
    letterSpacing: "0",
    zIndex: "1",
    outline: "inherit",
    borderRadius: "0.25rem",
    boxShadow: "0 0 8px #0001",
    padding: "1rem",
    textDecoration: "none",
    transition: "transform 0.3s",
  },
  itemMinWidth: "15rem",
};
