const widths = {
  "grid-md-12": 100,
  "grid-md-9": 75,
  "grid-md-8": 66,
  "grid-md-6": 50,
  "grid-md-4": 33,
  "grid-md-3": 25,
};

export default function useDynamicModules(modules, dynamicModules) {
  createDynamicModules(modules, dynamicModules);

  return dynamicModules;
}

function createDynamicModules(
  modules,
  dynamicModules,
  count = 0,
  tempArr = [],
) {
  if (!modules.length) return;

  for (let index = 0; index < modules.length; index++) {
    const currentModule = modules[index];
    const moduleWidth = currentModule.columnWidth
      ? widths[currentModule.columnWidth]
      : 100;

    if (count + moduleWidth > 100) {
      break;
    }

    count = count + moduleWidth;
    tempArr.push(currentModule);
  }

  if (tempArr) {
    dynamicModules?.keys.push(...tempArr.map((tempItem) => tempItem.key));
    dynamicModules?.modules.push({
      items: tempArr,
    });
  }

  createDynamicModules(
    modules.filter((item) => !dynamicModules.keys.includes(item.key)),
    dynamicModules,
  );
}
