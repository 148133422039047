import { useID, Icon, Button } from "@whitespace/components";
import * as styles from "@whitespace/gatsby-plugin-search/src/components/SearchForm.module.css";
import SelectField from "@whitespace/gatsby-plugin-search/src/components/SelectField";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CheckboxGroup from "../../../components/CheckboxGroup";
import useFilterValues from "../backend/minisearch/useFilterValues";

SearchForm.propTypes = {
  className: PropTypes.string,
  corner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    municipalities: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

function cornerMunicipalities(allMunicipalities, municipalities, t) {
  let data = {};
  Object.keys(allMunicipalities).map((municipality) => {
    if (municipalities.includes(allMunicipalities[municipality]))
      data[municipality] = allMunicipalities[municipality];

    if (municipality === "") {
      data[""] = t("municipalitiesAllLabel");
    }
  });

  return data;
}

export default function SearchForm({ className, corner, ...props }) {
  const {
    params,
    urlParams,
    setParams,
    schema,
    // facets,
    // features,
    documents,
  } = useSearch();
  const { t } = useTranslation();

  const generateID = useID();

  const { municipalities } = corner;
  const allMunicipalities = useFilterValues("municipalities");

  const allOrientations = useFilterValues("orientations");
  const allDurations = useFilterValues("duration");
  const allFlags = useFilterValues("flags");
  const allTypes = useFilterValues("types");
  // const allStudyPacesMap = useFilterValues("studyPaces");
  // const allStudyPaces = Array.from(allStudyPacesMap);
  const allStudyPaces = useFilterValues("studyPaces");
  const allCourseStartSemesters = useMemo(() => {
    let options = [
      ...new Set(
        (documents || [])
          .filter(
            corner.id
              ? (document) => document.corner === corner.id
              : () => true,
          )
          .flatMap((document) => document.courseStartSemesters),
      ),
    ]
      .filter(Boolean)
      .sort()
      .slice(0, 3);
    if (options.length > 0) {
      while (options.length < 3) {
        let lastOption = options[options.length - 1];
        let [year, semester] = lastOption.split("-");
        if (semester === "01") {
          semester = "02";
        } else {
          year = parseInt(year) + 1;
          semester = "01";
        }
        options.push(`${year}-${semester}`);
      }
    }
    return options.map((value) => {
      let year = value.split("-")[0];
      let label =
        value.split("-")[1] === "01"
          ? `Vår ${year} (jan–jun)`
          : `Höst ${year} (jul–dec)`;
      return { value, label };
    });
  }, [documents?.length, corner.id]);

  return (
    <Formik
      initialValues={params}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values) => {
        setParams({ ...values, page: null });
      }}
      {...props}
    >
      {({ setFieldValue, setValues, submitForm, values }) => {
        return (
          <Form className={clsx(styles.form, className)}>
            <div className={styles.filterContainer}>
              {"corners" in values && (
                <div className={styles.filterContent}>
                  <div id={generateID("corners-label")}>{t(`show`)}</div>
                  <CheckboxGroup
                    aria-labelledby={generateID("corners-label")}
                    options={{
                      "": t(`search.facetLabels.corner.${corner.id}`),
                      all: t(`search.facetLabels.corner.any`),
                    }}
                    name="corners"
                  />
                </div>
              )}

              <div className={styles.select}>
                {"orientations" in values && (
                  <SelectField
                    // className={styles.filterMulti}
                    name="orientations"
                    isMulti={false}
                    placeholder={t("orientationsAllLabel")}
                    value={values.orientations}
                    isSearchable={false}
                    onChange={(value) => {
                      setFieldValue("orientations", value);
                    }}
                    options={allOrientations}
                  />
                )}
                {"municipalities" in values && (
                  <SelectField
                    // className={styles.filterMulti}
                    name="municipalities"
                    isMulti={false}
                    placeholder={t("municipalitiesAllLabel")}
                    value={values.municipalities}
                    isSearchable={false}
                    onChange={(value) => {
                      setFieldValue("municipalities", value);
                    }}
                    options={
                      !values.corners
                        ? cornerMunicipalities(
                            allMunicipalities,
                            municipalities,
                            t,
                          )
                        : allMunicipalities
                    }
                  />
                )}
                {"duration" in values && (
                  <SelectField
                    name="duration"
                    isMulti={false}
                    placeholder={t("durationAllLabel")}
                    value={values.duration}
                    isSearchable={false}
                    onChange={(value) => {
                      setFieldValue("duration", value);
                    }}
                    options={allDurations}
                  />
                )}
              </div>
              <div className={styles.courses}>
                <div className={styles.filterContent}>
                  <div id={generateID("study-pace-label")}>
                    {t(`studyPaceLabel`)}
                  </div>
                  <CheckboxGroup
                    id={generateID("study-pace")}
                    aria-labelledby={generateID("study-pace-label")}
                    options={allStudyPaces}
                    name="studyPace"
                    multiple={true}
                  />
                </div>
                <div className={styles.filterContent}>
                  <div id={generateID("course-start-semesters-label")}>
                    {t(`courseStartSemestersLabel`)}
                  </div>
                  <CheckboxGroup
                    id={generateID("course-start-semesters")}
                    aria-labelledby={generateID("course-start-semesters-label")}
                    options={allCourseStartSemesters}
                    name="courseStartSemesters"
                    multiple={true}
                  />
                </div>
              </div>

              <div className={styles.filters}>
                <div className={styles.filterContent}>
                  <CheckboxGroup
                    id={generateID("application")}
                    // aria-labelledby={generateID("show-only-label")}
                    options={allFlags}
                    multiple={true}
                  />
                </div>
              </div>
              <div className={styles.filters}>
                <div className={styles.filterContent}>
                  <div id={generateID("show-only-label")}>
                    {t(`showOnlyLabel`)}
                  </div>
                  <CheckboxGroup
                    id={generateID("show-only")}
                    aria-labelledby={generateID("show-only-label")}
                    options={allTypes}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
            <div className={clsx(styles.footer)}>
              {Object.values(urlParams).length > 0 && (
                <Button
                  className={styles.clearFilter}
                  onClick={() => {
                    setValues({});
                    setTimeout(submitForm, 0);
                  }}
                >
                  <Icon name="icon-close" />
                  {t("clearFilterLabel")}
                </Button>
              )}
              <Button
                type="submit"
                className={styles.submitButton}
                onClick={() => {
                  setTimeout(submitForm, 0);
                }}
              >
                <Icon name="search" />
                {t("submitButtonLabel")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
