import { Button, Link } from "@whitespace/components/dist/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import capitalize from "../../utils/capitalize";

import * as defaultStyles from "./CornerCard.module.css";

CornerCard.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  compact: PropTypes.bool,
  name: PropTypes.string,
  theme: PropTypes.string,
  uri: PropTypes.string,
  mapIcon: PropTypes.string,
  municipalities: PropTypes.array,
};

export default function CornerCard({
  styles = defaultStyles,
  className,
  compact = false,
  name = "",
  theme,
  uri = "",
  mapIcon = "",
  municipalities = [],
}) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        styles.component,
        compact && styles.isCompact,
        styles[`theme${capitalize(theme)}`],
        className,
      )}
    >
      <img src={`/icons/${mapIcon}`} alt="" className={clsx(styles.map)} />
      <div className={clsx(styles.body)}>
        {compact ? (
          <h3 className={clsx(styles.title)}>
            <Link to={uri}>{name}</Link>
          </h3>
        ) : (
          <h3 className={clsx(styles.title)}>{name}</h3>
        )}

        <ul className={clsx(styles.municipalities)}>
          {municipalities.map((municipality, index) => (
            <li className={clsx(styles.municipalitiesItem)} key={index}>
              {municipality}
            </li>
          ))}
        </ul>

        {!compact && (
          <Button to={uri} className={clsx(styles.cta)}>
            {t("cornerPickerLabel")}
          </Button>
        )}
      </div>
    </div>
  );
}
