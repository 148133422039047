import { Link, Icon } from "@whitespace/components/dist/components";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./EducationHero.module.css";
import Occasions from "./Occasions";

EducationHero.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function EducationHero({ styles = defaultStyles, className }) {
  let {
    contentNode: {
      title,
      secondaryTitle: { title: subtitle },
      featuredImage,
      educationInformation: {
        orientation,
        courseStartDates,
        studyAddress,
        studyPace,
        duration,
        applicationLink,
        canceled,
        registrationPending,
        openForRegistration,
        vocationalAdults,
        apprenticeAdults,
        combinedLearning,
        remoteLearning,
      },
    },
  } = usePageContext();

  const { t } = useTranslation();
  const image = !!featuredImage && featuredImage.node;

  return (
    <div className={clsx(styles.component, className)}>
      {image && (
        <Image
          {...image}
          caption={null}
          credit={null}
          aspectRatio={1}
          className={clsx(styles.heroImage)}
        />
      )}
      <div className={styles.item}>
        <div className={styles.details}>
          <div className={styles.orientation}>
            {orientation.name}
            {vocationalAdults && (
              <span className={styles.orientationLabel}>
                {t("vocationalAdultsLabel")}
              </span>
            )}
            {apprenticeAdults && (
              <span className={styles.orientationLabel}>
                {t("apprenticeAdultsLabel")}
              </span>
            )}
            {remoteLearning && (
              <span className={styles.orientationLabel}>
                {t("remoteLearningLabelSingle")}
              </span>
            )}
            {combinedLearning && (
              <span className={styles.orientationLabel}>
                {t("combinedLearningLabel")}
              </span>
            )}
          </div>
          <div className={styles.title}>{title}</div>
          {subtitle && <div className={styles.subtitle}>- {subtitle}</div>}
          <Occasions
            dates={courseStartDates}
            cancelled={canceled}
            registrationPending={registrationPending}
            openForRegistration={openForRegistration}
          />
          <div className={styles.educationInfo}>
            <div className={styles.educationDurationAndPace}>
              {duration && (
                <div className={styles.educationDuration}>
                  <b className={styles.educationLabel}>
                    {t("educationLengthLabel")}
                  </b>
                  <div className={styles.duration}>
                    {duration + " " + t("weeksLabel")}
                  </div>
                </div>
              )}
              {studyPace && (
                <div className={styles.educationPace}>
                  <b className={styles.educationLabel}>{t("studyPaceLabel")}</b>
                  <div className={styles.studyPace}>
                    {t(`${studyPace}PaceLabel`)}
                  </div>
                </div>
              )}
            </div>

            {studyAddress?.address && (
              <div className={styles.educationAddress}>
                <b className={styles.educationLabel}>
                  {t("municipalityLabel")}
                </b>
                <div className={styles.studyPlace}>
                  <p>{studyAddress.organisation}</p>
                  <p>{studyAddress.address}</p>
                  <Link
                    to={
                      "https://www.google.com/maps/place/" +
                      studyAddress.address.split(" ").join("+")
                    }
                  >
                    {t("findLocationLabel")}
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className={styles.linkWrapper}>
            <Link className={styles.link} target="_blank" to={applicationLink}>
              {t("applyLabel")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
