export default function useApproval(needsApproval, texts) {
  if (!texts) {
    return null;
  }
  const index = needsApproval - 1;
  const content = Object.values(texts)[index];

  return (
    content && {
      index: index,
      content: content,
    }
  );
}
