import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useCornersInfo from "../../hooks/useCornersInfo";
import CornerCard from "../Corner/CornerCard.js";

import * as defaultStyles from "./CornerCardList.module.css";

CornerCardList.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  compact: PropTypes.bool,
};

export default function CornerCardList({
  styles = defaultStyles,
  className,
  compact = false,
  ...restProps
}) {
  const corners = useCornersInfo();

  return (
    <nav>
      <ul
        className={clsx(
          styles.component,
          compact && styles.isCompact,
          className,
        )}
      >
        {Object.keys(corners).map((key, index) => (
          <li key={index}>
            <CornerCard {...corners[key]} compact={compact} />
          </li>
        ))}
      </ul>
    </nav>
  );
}
