import { H } from "@jfrk/react-heading-levels";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

ArticleTitle.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  visuallyHidden: PropTypes.bool,
};

export default function ArticleTitle({
  styles = defaultStyles,
  className,
  visuallyHidden = false,
  ...restProps
}) {
  let {
    contentNode: { title },
  } = usePageContext();

  return (
    <H
      className={clsx(
        styles.component,
        className,
        visuallyHidden && utilities.visuallyHidden,
      )}
      {...restProps}
    >
      {title}
    </H>
  );
}
