import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

import {
  LandingPage,
  DefaultTemplate,
  EducationPage,
  SearchPage,
} from "../../../templates";

export default function SingleTemplate() {
  const { contentNode } = usePageContext();
  const template = contentNode?.pageAppearance?.template;
  const postType = contentNode?.contentType?.node?.name;

  const isEducationPage = postType?.includes("education");

  switch (template) {
    case "landingPage":
      return <LandingPage />;
    case "searchPage":
      return <SearchPage />;
    default:
      return isEducationPage ? <EducationPage /> : <DefaultTemplate />;
  }
}
