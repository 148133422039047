import { Link, withComponentDefaults } from "@whitespace/components";
import { TextContent } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import FooterMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/FooterMenu";
import clsx from "clsx";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import CornerCardList from "../../../components/CornerCardList/CornerCardList";
import * as layout from "../foundation/layout.module.css";

import * as defaultStyles from "./Footer.module.css";

export default withComponentDefaults(Footer, "footer");

Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

const useFooterSettings = () => {
  return useStaticQuery(graphql`
    query FooterSettings {
      wp {
        ...WP_FooterSettingsForHook
      }
    }
  `).wp;
};

function Footer({ styles = defaultStyles, className, ...restProps }) {
  const {
    acfOptionsFooter: {
      footerInfo: { text, readMore },
    },
  } = useFooterSettings();

  return (
    <footer className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(layout.component, className)}>
        <TextContent>
          <p>
            <strong>Skånevux</strong>
            <br />
            {text}
          </p>
          {readMore && (
            <Link to={readMore.url} target={readMore.target}>
              {readMore.title ? readMore.title : "Läs mer om Skånevux"}
            </Link>
          )}
        </TextContent>
        <CornerCardList compact={true} />
        <FooterMenu menuName="footer" />
      </div>
    </footer>
  );
}
