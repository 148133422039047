import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const useTaxonomies = () => {
  return useStaticQuery(graphql`
    query FilterTaxonomies {
      wp {
        municipalities(where: { orderby: NAME, childless: true }, first: 100) {
          nodes {
            name
            slug
          }
        }
        orientations(where: { orderby: NAME }, first: 100) {
          nodes {
            name
            slug
          }
        }
      }
    }
  `).wp;
};

export default function useFilterValues(taxonomy) {
  const { t } = useTranslation();

  const taxonomies = useTaxonomies();
  let filterValues = {};

  switch (taxonomy) {
    case "duration":
      filterValues = {
        "": t("durationAllLabel"),
        "0-20": `< 20 ${t("weeksLabel")}`,
        "21-40": `21–40 ${t("weeksLabel")}`,
        "41-60": `41–60 ${t("weeksLabel")}`,
        "61-520": `> 60 ${t("weeksLabel")}`,
      };
      break;
    case "flags":
      filterValues = {
        openForRegistration: t("openForRegistrationLabel"),
      };
      break;
    case "types":
      filterValues = {
        remoteLearning: t("remoteLearningLabel"),
        vocationalAdults: t("vocationalAdultsLabel"),
        apprenticeAdults: t("apprenticeAdultsLabel"),
        combinedLearning: t("combinedLearningLabel"),
      };
      break;
    case "studyPaces":
      filterValues = [
        {
          value: "100",
          label: t("100PaceLabel"),
        },
        { value: "75", label: t("75PaceLabel") },
        { value: "50", label: t("50PaceLabel") },
      ];
      break;
    case "orientations":
      filterValues[""] = t(`${taxonomy}AllLabel`);
      taxonomies[taxonomy]?.nodes
        .sort((a, b) => {
          const order = [
            "Barn och fritid",
            "Bygg och anläggning",
            "El och energi",
            "Fordon och transport",
            "Försäljning och service",
            "Hantverk",
            "Hotell och turism",
            "Industri",
            "Naturbruk",
            "Restaurang och livsmedel",
            "VVS och fastighet",
            "Vård och omsorg",
            "Särskild utbildning",
          ];
          return order.indexOf(a.name) - order.indexOf(b.name);
        })
        .forEach(({ slug, name }) => (filterValues[slug] = name));
      break;
    default:
      filterValues[""] = t(`${taxonomy}AllLabel`);
      taxonomies[taxonomy]?.nodes
        .sort((a, b) =>
          a.name.localeCompare(b.name, "sv", { sensitivity: "case" }),
        )
        .forEach(({ slug, name }) => (filterValues[slug] = name));
      break;
  }

  return filterValues;
}
