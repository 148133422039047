import { isEmpty } from "lodash";

export default function isEmptySearch({
  sortBy,
  sortOrder,
  from,
  size,
  ...params
}) {
  return false;
}
