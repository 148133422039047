import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./EducationCourses.module.css";

EducationCourses.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function EducationCourses({
  styles = defaultStyles,
  className,
}) {
  let {
    contentNode: {
      educationCourses: { courses },
      educationEligibleCourses: { eligibleCourses },
    },
  } = usePageContext();

  const { t } = useTranslation();

  const data = [
    courses && {
      title: t("coursesLabel"),
      items: [...courses],
      showTotalCount: true,
    },
    eligibleCourses && {
      title: t("eligibleCoursesLabel"),
      items: [...eligibleCourses],
      showTotalCount: false,
    },
  ];
  return (
    <div className={clsx(styles.component, className)}>
      {data.map((item, index) => {
        let total = 0;
        return (
          item && (
            <div key={index}>
              <h2 className={styles.heading}>{item.title}</h2>
              <div className={styles.content}>
                <table>
                  <thead>
                    <tr>
                      <th scope="col">{t("courseCodeLabel")}</th>
                      <th scope="col">{t("courseNameLabel")}</th>
                      <th scope="col">{t("pointLabel")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.items.map(({ code, name, credits }, index) => {
                      if (credits) total += credits;
                      return (
                        <tr key={index}>
                          <td>{code}</td>
                          <td>{name}</td>
                          <td>{credits}</td>
                        </tr>
                      );
                    })}
                    {item.showTotalCount && (
                      <tr className={styles.bold}>
                        <th scope="row">{t("totalLabel")}</th>
                        <td />
                        <td>{total}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}
