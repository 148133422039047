import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Checkbox from "./Checkbox";
import * as styles from "./CheckboxGroup.module.css";

function normalizeOption(option) {
  if (typeof option !== "object") {
    return { value: option, label: option };
  }
  return option;
}

function normalizeOptions(options) {
  if (!Array.isArray(options)) {
    return Object.entries(options).map(([value, rest]) => ({
      ...normalizeOption(rest),
      value,
    }));
  }
  return options && options.map(normalizeOption);
}

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  itemAppearance: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.any,
  options: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
  ]),
};

export default function CheckboxGroup({
  className,
  id,
  name,
  options,
  multiple = false,
  ...restProps
}) {
  let normalizedOptions = normalizeOptions(options);
  return (
    <>
      <div
        role="group"
        className={cx(styles.group, className)}
        id={id}
        {...restProps}
      >
        {normalizedOptions.map((option, index) => {
          return (
            <Checkbox
              key={`${option.name || name}:${option.value}`}
              name={option.name || name || option.value}
              id={`${id || option.name || name}-${index}`}
              {...option}
              value={option.name || name ? option.value : undefined}
              type={multiple ? "checkbox" : "radio"}
            />
          );
        })}
      </div>
    </>
  );
}
