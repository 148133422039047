import { Section } from "@jfrk/react-heading-levels";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { withComponentDefaults } from "@whitespace/components";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import * as articleTitleStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import {
  usePageContext,
  useHTMLProcessor,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import React from "react";

import * as defaultStyles from "./Education.module.css";
import EducationBody from "./EducationBody";
import EducationCourses from "./EducationCourses";
import EducationHero from "./EducationHero";
import EducationMeta from "./EducationMeta";

export default withComponentDefaults(Education, "education");

function Education({
  className,
  styles = defaultStyles,
  // displayMode,
  ...restProps
}) {
  const { processContent } = useHTMLProcessor();

  let {
    contentNode: {
      secondaryTitle: { title: subtitle },
      educationInformation: { orientation },
      contentArea,
      rightSidebar,
    },
  } = usePageContext();

  if (!orientation) return null;

  return (
    <article className={clsx(styles.component, className)} {...restProps}>
      <EducationHero />
      <div className={clsx(layout.component, layout.componentWithSidebar)}>
        <div className={layout.content}>
          <div className={articleTitleStyles.hgroup}>
            <p className={articleTitleStyles.eyebrow}>{orientation.name}</p>
            <ArticleTitle />
            <p className={articleTitleStyles.subtitle}>{subtitle}</p>
          </div>

          <Section>
            <EducationBody />
            <EducationMeta />
            <EducationCourses />
            <ModularityArea area={contentArea} />
          </Section>
        </div>
        <div className={layout.sidebar}>
          <Section>
            <ModularityArea area={rightSidebar} />
          </Section>
        </div>
      </div>
    </article>
  );
}
