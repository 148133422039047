import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";
import { useTranslation } from "react-i18next";

export default function useEducationInfo({
  studyAddress,
  courseInfo,
  requirements,
  industryRequirements,
  validation,
  application,
  informationMeeting,
  apl,
  approval,
}) {
  const { processContent } = useHTMLProcessor();
  const { t } = useTranslation();

  return [
    studyAddress?.address && {
      title: t("municipalityLabel"),
      type: "studyAddress",
      data: (
        <>
          <p>{studyAddress.organisation}</p>
          <p>{studyAddress.address}</p>
        </>
      ),
    },
    courseInfo && {
      title: t("courseInfoLabel"),
      type: "courseInfo",
      data: processContent(courseInfo),
    },
    requirements && {
      title: t("requirementsLabel"),
      type: "requirements",
      data: requirements,
    },
    industryRequirements && {
      title: t("industryRequirementsLabel"),
      type: "industryRequirements",
      data: processContent(industryRequirements),
    },
    validation && {
      title: t("validationLabel"),
      type: "validation",
      data: processContent(validation),
    },
    application && {
      title: t("applicationLabel"),
      type: "application",
      data: processContent(application),
    },
    informationMeeting && {
      title: t("infoMeetingLabel"),
      type: "informationMeeting",
      data: processContent(informationMeeting),
    },
    apl && {
      title: t("aplLabel"),
      type: "apl",
      data: processContent(apl),
    },
    approval && {
      title: t(`approval${approval.index}Label`),
      type: "needsApproval",
      data: approval.content,
    },
  ];
}
