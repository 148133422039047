import { H } from "@jfrk/react-heading-levels";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useApproval from "../hooks/useApproval";
import useEducationInfo from "../hooks/useEducationInfo";
import useEducationSettings from "../hooks/useEducationSettings";
import useRequirements from "../hooks/useRequirements";

import * as defaultStyles from "./EducationMeta.module.css";

EducationMeta.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function EducationMeta({ styles = defaultStyles, className }) {
  let {
    contentNode: {
      corner,
      educationInformation: {
        needsApproval, //Fritt sök, text hämtas på ett annat sätt
      },
      educationOtherContent: {
        apl,
        application,
        courseInfo,
        informationMeeting,
        validation,
        requirements,
      },
    },
  } = usePageContext();

  const { complementaryTexts } = useEducationSettings(corner);

  const toggleInfo = useEducationInfo({
    courseInfo: courseInfo,
    requirements: useRequirements(requirements),
    industryRequirements: requirements.industryRequirements,
    validation: validation,
    application: application,
    informationMeeting: informationMeeting,
    apl: apl,
    approval: useApproval(needsApproval, complementaryTexts),
  });

  return (
    <div className={clsx(styles.component, className)}>
      <div>
        {toggleInfo.map((info, index) => {
          return (
            info && (
              <div className={clsx(styles.item)} key={index}>
                <H className={clsx(styles.itemTitle)}>{info.title}</H>
                {info.data}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
