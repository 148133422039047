import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ScrollElevator.module.css";

ScrollElevator.propTypes = {
  scrollY: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ScrollElevator({
  scrollY = 300,
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  const onClick = (e) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onScroll = () => {
    setHidden(window.scrollY <= scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className={clsx(styles.component, className, hidden && styles.hidden)}>
      <a href={"#top"} className={styles.link} onClick={onClick}>
        <span className={styles.icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-up-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
          </svg>
        </span>
        {t("takeMeToTheTop")}
      </a>
    </div>
  );
}
