// extracted by mini-css-extract-plugin
export var columns = "ContactCard-module--columns--Wc-EY";
export var columnsNarrow = "ContactCard-module--columnsNarrow--DN1H3";
export var component = "ContactCard-module--component--ieGBo";
export var divider = "ContactCard-module--divider--dRHlF";
export var headline = "ContactCard-module--headline--NBIu8";
export var iconLabel = "ContactCard-module--iconLabel--rSMGi";
export var image = "ContactCard-module--image--ZJJIp";
export var label = "ContactCard-module--label--L3lAI";
export var photo = "ContactCard-module--photo--obgoe";
export var social = "ContactCard-module--social--CyN22";
export var wrapper = "ContactCard-module--wrapper--6Ceo1";