// extracted by mini-css-extract-plugin
export var content = "SearchHit-module--content--nCh6Z";
export var info = "SearchHit-module--info--yGrCx";
export var infoItem = "SearchHit-module--infoItem--rUAii";
export var infoItemText = "SearchHit-module--infoItemText--uv+j8";
export var infoItemTitle = "SearchHit-module--infoItemTitle--sH+s4";
export var link = "SearchHit-module--link--Tgo5I";
export var meta = "SearchHit-module--meta--8Mh1M";
export var occasions = "SearchHit-module--occasions--WZZ1T";
export var readMore = "SearchHit-module--readMore--ULsyp";
export var teaser = "SearchHit-module--teaser--mrKyJ";
export var title = "SearchHit-module--title--NAAP1";
export var titleLink = "SearchHit-module--titleLink--UuCaa";
export var toggleButton = "SearchHit-module--toggleButton--fEfmO";
export var toggleInfo = "SearchHit-module--toggleInfo--8JLDc";
export var toggleWrapper = "SearchHit-module--toggleWrapper--H4YMn";