import { Section } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import ArticleFeaturedImage from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage";
import ArticleMeta from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta";
import ArticleTagList from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTagList";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import * as articleTitleStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import {
  usePageContext,
  useIsFullWidthPage,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import React from "react";

import * as layout from "../foundation/layout.module.css";

export default withComponentDefaults(Article, "article");

function Article({
  className,
  styles = defaultStyles,
  // displayMode,
  ...restProps
}) {
  const { contentNode } = usePageContext();
  const title = contentNode?.title;
  const blocksJSON = contentNode?.blocksJSON;
  const template = contentNode?.pageAppearance?.template;
  const isFrontPage = contentNode?.isFrontPage;

  let isFullWidthPage = false;
  let isLandingPage = false;

  if (template) {
    isFullWidthPage = useIsFullWidthPage();
    isLandingPage = template == "landingPage";
  }

  if (!contentNode) {
    return null;
  }

  return (
    <article className={clsx(layout.component, className)} {...restProps}>
      {!isFrontPage || (!isLandingPage && <ArticleFeaturedImage />)}
      <div
        className={clsx(
          layout.component,
          isFullWidthPage
            ? layout.componentWidthFull
            : layout.componentWidthNarrow,
        )}
      >
        {title && (
          <div
            className={clsx(
              articleTitleStyles.hgroup,
              (isFrontPage || isLandingPage) && utilities.visuallyHidden,
            )}
          >
            <ArticleTitle />
          </div>
        )}
        <Section>
          <ArticleBody />
          {!isFrontPage && !isLandingPage && (
            <footer className={styles.footer}>
              <ArticleMeta />
              <ArticleTagList taxonomy="tags" />
            </footer>
          )}
        </Section>
      </div>
    </article>
  );
}
