import { parse as parseDate, format as formatDate } from "date-fns";
import { graphql, useStaticQuery } from "gatsby";

function htmlToText(html) {
  return html && html.replace(/(<([^>]+)>)/gi, "");
}

function mapDuration(duration) {
  if (!duration) {
    return null;
  }
  if (duration <= 20) {
    return "0-20";
  }
  if (duration <= 40) {
    return "21-40";
  }
  if (duration <= 60) {
    return "41-60";
  }
  return "61-520";
}

const applicationDeadlineComparison = formatDate(new Date(), "yyyy/MM/dd");

function defaultContentNodeFields(source) {
  return {
    id: source.id,
    url: source.url || source.uri,
    title: source.title,
    secondaryTitle: source.secondaryTitle?.title,
    courses: source.educationCourses?.courses,
    corner: source.corner,
    eligibleCourses: source.educationEligibleCourses?.eligibleCourses,
    educationInformation: source.educationInformation,
    educationOtherContent: source.educationOtherContent,
    contentType: source.contentType?.node.name,
    label: source.title,
    date: source.dateGmt,
    text: [
      // Post content
      htmlToText(source.content),
      // Modularity modules
      source.contentArea?.modules.map(({ node: module }) => [
        // Module title if not hidden
        !module?.hideTitle && module?.title,
        // Module content (in text modules etc)
        htmlToText(module?.content),
        // Manual input in Posts module
        module?.dataSource?.data?.map((data) => [
          data.postTitle,
          htmlToText(data.postContent),
        ]),
      ]),
    ],
    municipalities: source.educationInformation?.municipality?.slug,
    orientations: source.educationInformation?.orientation?.slug,
    duration: mapDuration(source.educationInformation?.duration),
    openForRegistration: !!source.educationInformation?.openForRegistration,
    remoteLearning: !!source.educationInformation?.remoteLearning,
    vocationalAdults: !!source.educationInformation?.vocationalAdults,
    apprenticeAdults: !!source.educationInformation?.apprenticeAdults,
    combinedLearning: !!source.educationInformation?.combinedLearning,
    studyPace: source.educationInformation?.studyPace,
    courseStartSemesters:
      source.educationInformation?.courseStartDates
        ?.map(({ startDate, applicationDeadline }) => {
          if (
            applicationDeadline &&
            applicationDeadline < applicationDeadlineComparison
          ) {
            return null;
          }
          let [year, month] =
            startDate?.match(/^(\d{4})\/(\d{2})/)?.slice(1) || [];
          if (year && month) {
            return `${year}-${month < 7 ? "01" : "07"}`;
          }
        })
        .filter(Boolean) || [],
  };
}

export default function useSearchDocuments() {
  const {
    graphQlQuery: { data },
  } = useStaticQuery(graphql`
    query WPNodesForMiniSearch_Skanevux {
      graphQlQuery(name: { eq: "WPContentNodesForMiniSearch" }) {
        data
      }
    }
  `);

  const docs = [
    data.contentNodes.nodes
      .map((source) => ({
        ...defaultContentNodeFields(source),
        tags: source.tags?.nodes,
      }))
      .sort((a, b) => {
        return a.openForRegistration === b.openForRegistration
          ? 0
          : a.openForRegistration
          ? 1
          : -1;
      }),
  ];

  return docs;
}
