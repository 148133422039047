import { withComponentDefaults, SkipTo } from "@whitespace/components";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import {
  usePages,
  usePageChildren,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import CornerSelector from "../../../components/CornerSelector/CornerSelector";
import SubPageNavigation from "../../../components/SubPageNavigation";
import * as layout from "../foundation/layout.module.css";

import * as defaultStyles from "./Header.module.css";
import HeaderFlyOutMenu from "./HeaderFlyOutMenu";
import HeaderLogo from "./HeaderLogo";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default withComponentDefaults(Header, "header");

function Header({ styles = defaultStyles, className, corner, ...restProps }) {
  const { t } = useTranslation();

  const allPages = usePages();

  const page = corner && allPages.find(({ uri }) => uri === `/${corner}/`);
  let childPages = usePageChildren(page?.id);

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.componentInner, layout.component, className)}>
        <SkipTo />
        <HeaderLogo linkTo="/" />
        <div>
          <HeaderMainMenu aria-label={t("helpMenu")} menuName={"helpMenu"} />
        </div>
        <HeaderFlyOutMenu />
      </div>
      <CornerSelector />
      {childPages.length > 0 && <SubPageNavigation pages={childPages} />}
    </header>
  );
}
