import { H } from "@jfrk/react-heading-levels";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import React from "react";

import SearchForm from "../@whitespace/gatsby-plugin-search/components/SearchForm";
import * as layout from "../@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import useEducationSettings from "../hooks/useEducationSettings";

import * as styles from "./SearchHeader.module.css";

export default function SearchHeader({ corner, className, ...restProps }) {
  const {
    searchHero: { title, text, image },
  } = useEducationSettings(corner.theme);

  return (
    <div className={clsx(styles.component, className)}>
      <Image
        className={styles.image}
        {...image}
        caption={null}
        credit={null}
        aspectRatio={1440 / 320}
      />
      <div className={clsx(layout.component, styles.wrapper)}>
        <div className={clsx(styles.content)}>
          <H className={clsx(styles.title)}>{title}</H>
          <p className={clsx(styles.text)}>{text}</p>
        </div>
        <SearchForm corner={corner} />
      </div>
    </div>
  );
}
