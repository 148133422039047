// extracted by mini-css-extract-plugin
export var component = "EducationHero-module--component--wZdL4";
export var educationAddress = "EducationHero-module--educationAddress--Yx9Zd";
export var educationDuration = "EducationHero-module--education-duration--zLCI6";
export var educationDurationAndPace = "EducationHero-module--education-duration-and-pace--s1cGD";
export var educationInfo = "EducationHero-module--education-info--L+YU9";
export var educationLabel = "EducationHero-module--educationLabel--28cn4";
export var educationPace = "EducationHero-module--education-pace--9f3tX";
export var heroImage = "EducationHero-module--heroImage--TTC7s";
export var item = "EducationHero-module--item--RSAFD";
export var link = "EducationHero-module--link--TdbXZ";
export var linkWrapper = "EducationHero-module--link-wrapper--cFmwQ";
export var orientation = "EducationHero-module--orientation--IGCW+";
export var orientationLabel = "EducationHero-module--orientationLabel--Gp9vc";
export var subtitle = "EducationHero-module--subtitle--rlAWR";
export var title = "EducationHero-module--title--g0iCh";