import { useLocation } from "@reach/router";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import ScrollElevator from "../../../components/ScrollElevator";
import useCornersInfo from "../../../hooks/useCornersInfo";
import { UserContextProvider } from "../../../hooks/useUserContext";
import capitalize from "../../../utils/capitalize";
import * as defaultThemeStyles from "../foundation/theme.module.css";

import Footer from "./Footer";
import Header from "./Header";
import * as defaultStyles from "./SiteLayout.module.css";

SiteLayout.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  themeStyles: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.object,
};

export default function SiteLayout({
  styles = defaultStyles,
  themeStyles = defaultThemeStyles,
  className,
  children,
}) {
  const [userContext, setUserContext] = useState({});
  const corners = useCornersInfo();
  const {
    position = "",
    active = false,
    strings = [],
  } = useCookieConsentSettings();

  const { contentNode: { corner } = {}, contentType } = usePageContext();

  const { pathname } = useLocation();

  useEffect(() => {
    let theme = corner;
    const path = pathname.split("/");

    if (!theme) {
      theme = Object.keys(corners).find((corner) => path.includes(corner));
    }

    setUserContext({ theme: theme });
  }, [corner]);

  return (
    <UserContextProvider value={[userContext, setUserContext]}>
      <Helmet>
        <title>Skånevux</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-9KTV75DHLD"
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9KTV75DHLD');
          `}
        </script>
      </Helmet>
      <div
        id="top"
        className={clsx(
          styles.component,
          themeStyles.theme,
          userContext.theme &&
            themeStyles[`theme${capitalize(userContext.theme)}`],
          className,
        )}
      >
        <Header corner={userContext.theme} />
        <main className={styles.main} id="main">
          {children}
        </main>
        <Footer />
        <ScrollElevator />
        <CookieConsent
          position={position}
          cookieConsentSettings={strings}
          active={active}
        />
      </div>
    </UserContextProvider>
  );
}
