import { Button } from "@whitespace/components/dist/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as layout from "../../@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import useCornersInfo from "../../hooks/useCornersInfo";
import { useUserContext } from "../../hooks/useUserContext";
import CornerCardList from "../CornerCardList/CornerCardList";

import * as defaultStyles from "./CornerSelector.module.css";

CornerSelector.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function CornerSelector({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const { contentNode: { isFrontPage } = {} } = usePageContext();
  const [{ theme: corner }] = useUserContext();

  const corners = useCornersInfo();
  const [expanded, setExpanded] = useState(isFrontPage);

  useEffect(() => {
    if (corner) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [corner]);

  if (!isFrontPage && !corner) {
    return null;
  }

  const Selector = () => {
    return (
      <>
        <div className={clsx(styles.body, className)}>
          <h2 className={clsx(styles.title, className)}>
            {t("cornerPickerTitle")}
          </h2>
          <p>{t("cornerPickerLead")}</p>
        </div>
        <CornerCardList />
      </>
    );
  };

  const Compact = () => {
    return (
      <>
        <p>
          {t("cornerPickerSelected", {
            name: corners[corner]?.name,
          })}
          <br />
          {t("cornerPickerSelectedAction", {
            area: corners[corner]?.area,
          })}
        </p>
        <Button className={clsx(styles.cta)} onClick={() => setExpanded(true)}>
          Byt hörn
        </Button>
      </>
    );
  };

  return (
    <div
      className={clsx(
        styles.component,
        expanded && styles.isExpanded,
        className,
      )}
      {...restProps}
    >
      <div className={layout.component}>
        {expanded ? <Selector /> : <Compact />}
      </div>
    </div>
  );
}
