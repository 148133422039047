import { H, Section } from "@jfrk/react-heading-levels";
import Box from "@municipio/gatsby-theme-basic/src/components/Box";
import ContactCard from "@municipio/gatsby-theme-basic/src/components/modularity-modules/ContactCard";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ContactsModule.module.css";

ContactsModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
  module: PropTypes.shape({
    modContactsOptions: PropTypes.shape({ contacts: PropTypes.array }),
  }),
};

export default function ContactsModule({
  styles = defaultStyles,
  className,
  title,
  module = {},
  ...restProps
}) {
  const { modContactsOptions: { contacts } = {} } = module;

  return (
    <ModuleWrapper styles={styles} title={title} {...restProps}>
      {contacts.map((contact, index) => {
        return <ContactCard contact={contact} key={index} />;
      })}
    </ModuleWrapper>
  );
}
